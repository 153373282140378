<template>
  <div class="container-page" style="padding-left: 40px; padding-right: 40px">
    <div class="about-section">
      <div class="two-columns">
        <div>
          <h2>Welcome to Cutting Templates!</h2>
          <div class="paragraph body-1">
            Cutting Templates is a personal hobby to satisfy my own laser
            cutting template and pattern needs. Similar projects can be found,
            but I find their interaction and/or possibilities somewhat limiting.
            As such, I am creating my own tools which I will accumulate into
            this site. This way my work can get used by more people, giving back
            to you, the creative community across the globe!
          </div>
        </div>
        <div>
          <img src="../assets/lasercutter.png" width="256" height="256" />
        </div>
      </div>
    </div>
    <div class="about-section">
      <h2>Vision</h2>
      <div class="paragraph body-1">
        Cutting Templates is to become a source for high quality and
        customizable laser cutting templates. They should be highly available
        and therefor free! Now and in the future.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutPage",
};
</script>

<style scoped>
.about-section {
  padding: 20px;
  background: var(--main-bg-color);
  border-bottom: 2px solid var(--secondary-bg-color);
  border-radius: 15px;
  box-shadow: var(--first-shadow), var(--second-shadow);
}

.container-page {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.two-columns {
  display: flex;
  flex-direction: row;
  align-items: center;
}

h2 {
  margin-top: 0px;
}
</style>
